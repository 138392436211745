import { useEffect } from 'react';

const useScript = (url: string, id: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    script.id = id;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url, id]);
};

export default useScript;
import { detect } from 'detect-browser';
const browser = detect();

const checkBrowser = () => {
    switch (browser && browser.name) {
        case 'chrome':
        case 'firefox':
        case 'safari':
        case 'edge':
        case 'edge-chromium':
            return true;

        default:
            return false;
    }
}

export { checkBrowser }
import React from 'react';
import { useAuth0 } from '../utilities/react-auth0-spa';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import logoImg from '../assets/img/logo.svg';


type navbarProps = {
    toggleSidebar: any
}

const NavBar = (props: navbarProps) => {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    
    return (
        <div className='navbar bg-primary light-text'>
            <div className='brand-container'>
                <IconButton className='menu-btn no-outline-btn' edge='start' color='inherit' aria-label='menu' onClick={() => props.toggleSidebar()}>
                    <MenuIcon />
                </IconButton>
                <Link to='/'>
                    <img className='brand' src={logoImg} alt='Agrigate-logo' />
                </Link>
                <div className='sub-brand'>
                    professional
                </div>
            </div>
            <div className='navbar-detail-container'>
                <div className='link-container'>
                    <Link className='link' to='/reports'>Reports</Link>
                    <Link className='link' to='/farms'>Farms</Link>
                </div>
                {!isAuthenticated && (
                    <Button className='login-btn transparent-bg no-outline-btn' color='inherit' onClick={() => loginWithRedirect({})}>Log in</Button>
                )}
                {isAuthenticated &&
                    <div className='user-detail-container'>
                        <div className='user-detail'>
                            <div className='name'>{user.given_name} {user.family_name}</div>
                            <div>{user.email}</div>
                        </div>
                        <div className='logout-btn'>
                            <Button color='inherit' className='transparent-bg no-outline-btn' onClick={() => logout()}>Log out</Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default NavBar;
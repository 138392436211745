import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


const Loading = () => {
    return (
        <div className='flex-center loading'>
            <h1 className='margin-right' style={{ fontSize: '50px' }}>Loading...</h1>
            <CircularProgress color='primary' />
        </div>
    );
}

export default Loading;
import React, { useState } from 'react';
import NavBar from './navbar';
import SideBar from './sidebar';
import { checkBrowser } from '../utilities/browserSupport';
import Alert from '@material-ui/lab/Alert';

const HeaderWrapper = () => {
    const [open, setOpen] = useState<boolean>(false); //side bar open 
    const isSupported = checkBrowser();
    const pathname = window.location.pathname;

    const toggleSidebar = (action?: boolean) => {
        if (action === undefined) {
            setOpen(open => !open);
        } else {
            setOpen(action);
        }
    }

    return (
        <header>
            {(pathname.indexOf('/resetPassword') === -1 && pathname.indexOf('/verifyEmail') === -1) &&
                < React.Fragment >
                <NavBar toggleSidebar={toggleSidebar} />
                    {!navigator.onLine &&
                <Alert severity='warning'>You are currently offline</Alert>
            }
            {!isSupported &&
                <Alert severity='warning'>Your browser is not supported. For the best experience, we recommend using Google Chrome, Firefox, Safari or Microsoft Edge.</Alert>
            }
            <SideBar open={open} toggleSidebar={toggleSidebar} />
                </React.Fragment>
            }
        </header >
    );
}

export default HeaderWrapper;
import axios from 'axios';
import config from '../config.json';

const apiConfig = config.api;
const baseUrl = apiConfig.baseUrl;
const subscriptionKey = apiConfig.apimsubscriptionKey;


const instance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Ocp-Apim-Subscription-Key': subscriptionKey,
        'Ocp-Apim-Trace': 'True'
    }
});

const setAuthHeader = (token: string) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

const getReportsPath = '/pbi/GetReports';
const getTokenPath = '/pbi/GenerateToken';
const getFarmsPath = '/data/farms';
const getTargetPath = '/data/targets';
const getContactPath = '/crm/crm/contacts';
const getCompanyPath = '/crm/crm/companies';//TODO
const getAuthTokenPath = '/auth/adToken';
const getAuth0TokenPath = '/auth/token';
const getUserInfoPath = '/auth/userInfo';

export {
    instance, getReportsPath, getTokenPath, getFarmsPath,
    getTargetPath, subscriptionKey, getContactPath, getCompanyPath,
    getAuthTokenPath, getUserInfoPath, getAuth0TokenPath, setAuthHeader
};
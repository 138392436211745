import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { instance as axiosInstance, getContactPath, getCompanyPath, setAuthHeader, getAuthTokenPath } from './axios';
import { trackException, setInsightAuth, clearInsightAuth } from './appInsights';
import config from '../config.json';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

type ContextProps = {
  isAuthenticated: any,
  user: any,
  organisationId: string,
  loading: boolean,
  popupOpen: boolean,
  loginWithPopup: any,
  handleRedirectCallback: any,
  getIdTokenClaims: any,
  loginWithRedirect: any,
  getTokenSilently: any,
  getTokenWithPopup: any,
  logout: any
};


type auth0Props = {
  children: any,
  onRedirectCallback: any,
  domain: string,
  client_id: string,
  redirect_uri: string,
  cacheLocation: any,
  useRefreshTokens: boolean
}


export const Auth0Context = React.createContext<ContextProps>(null);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: auth0Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>();
  const [user, setUser] = useState<any | null>();
  const [auth0Client, setAuth0] = useState<any | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [organisationId, setOrganisationId] = useState<string>();
  const tokenConfig = config.api;


  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=') &&
        window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        await setDataAfterAuth(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);



  const getAPIAccessToken = async () => {
    await axiosInstance.get(getAuthTokenPath)
      .then(result => {
        if (result.data) {
          setAuthHeader(result.data.access_token);
        }
      });
  }

  const getOrgnisationId = async (userId: string): Promise<string> => {
    let organisationId = '';
    if (userId) {
      await axiosInstance.get(`${getContactPath}/${userId}`).then(
        async contactResult => {
          if (contactResult.data.associatedcompanyid) {
            const companyId = contactResult.data.associatedcompanyid;
            await axiosInstance.get(`${getCompanyPath}/${companyId}`).then(
              companyResult => {
                organisationId = companyResult.data.organization_id;
              }).catch((e) => trackException(e));
          }
        }).catch((e) => trackException(e));
    }
    return organisationId;
  }

  const setDataAfterAuth = async (user) => {
    await getAPIAccessToken();
    const userId = user.sub.split('|')[1];
    const orgnisationId = await getOrgnisationId(userId);
    setUser(user);
    setOrganisationId(orgnisationId);
    setInsightAuth(userId);
  }

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      trackException(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    await setDataAfterAuth(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    await setDataAfterAuth(user);
    setLoading(false);
    setIsAuthenticated(true);
  };

  const logout = async () => {
    window.localStorage.clear();
    clearInsightAuth();
    await auth0Client.logout();
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        organisationId,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
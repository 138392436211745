import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import logoImg from '../assets/img/logo.svg';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useAuth0 } from '../utilities/react-auth0-spa';
import Button from '@material-ui/core/Button';



type sidebarProps = {
    toggleSidebar: any,
    open: boolean
}
const SideBar = (props: sidebarProps) => {
    const { isAuthenticated, logout, user } = useAuth0();


    function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
        return <ListItem button component='a' {...props} />;
    }

    return (
        <Drawer anchor={'left'} open={props.open} onClose={() => props.toggleSidebar(false)} className='sidebar'>
            <div
                role='presentation'
                onClick={() => props.toggleSidebar(false)}
                onKeyDown={() => props.toggleSidebar(false)}
                className='list-container'
            >
                <List>
                    <div className='top-container'>
                        <IconButton className='close-btn' edge='start' color='inherit' aria-label='menu' onClick={() => props.toggleSidebar(false)}>
                            <CloseIcon />
                        </IconButton>
                        <img className='logo' src={logoImg} alt='Agrigate-logo' />
                    </div>
                    <ListItemLink href='/reports'>
                        <ListItemText primary='Reports' />
                    </ListItemLink>
                    <ListItemLink href='/farms'>
                        <ListItemText primary='Farms' />
                    </ListItemLink>
                </List>
            </div>
            {isAuthenticated &&
                <div className='sidebar-footer'>
                    <div className='user-detail'>
                        <div>{user.given_name} {user.family_name}</div>
                        <div> {user.email}</div>
                    </div>
                    <div className='logout-btn'>
                        <Button color='inherit' className='transparent-bg no-outline-btn' onClick={() => logout()}>Log out</Button>
                    </div>
                </div>
            }
        </Drawer>
    );
}

export default SideBar;
import React, { lazy, Suspense } from 'react';
import './style/App.scss';
import { useAuth0 } from './utilities/react-auth0-spa';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './components/privateRoute';
import HeaderWrapper from './components/headerWrapper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './style/theme';
import Loading from './components/loading';
import usePageViews from './utilities/hooks/usePageViews';
import useScript from './utilities/hooks/useScript';
import TimeoutAlert from './utilities/component/timeoutAlert';
const Reports = lazy(() => import('./screens/reports'));
const Targets = lazy(() => import('./screens/targets'));
const Farms = lazy(() => import('./screens/farms'));
const NotFound = lazy(() => import('./screens/notFound'));
const ResetPassword = lazy(() => import('./screens/resetPassword'));
const VerifyEmail = lazy(() => import('./screens/verifyEmail'));

const App = () => {
  const { loading } = useAuth0();
  usePageViews();

  useScript('//js.hs-scripts.com/6566723.js', 'hs-script-loader');

  return (
    <div className='App'>
      <MuiThemeProvider theme={theme}>
        {loading ?
          <Loading />
          :
          <React.Fragment>
            <TimeoutAlert />
            <HeaderWrapper />
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route path='/' exact>
                  <Redirect to='reports' />
                </Route>
                <Route path='/resetPassword' component={ResetPassword} exact />
                <Route path='/verifyEmail' component={VerifyEmail} exact />
                <PrivateRoute path='/reports' component={Reports} exact />
                <PrivateRoute path='/farms' component={Farms} exact />
                <PrivateRoute path='/:farmName/:farmId/targets' component={Targets} exact />
                <PrivateRoute path='*' component={NotFound} />
              </Switch>
            </Suspense>
          </React.Fragment>
        }
      </MuiThemeProvider>
    </div >
  );
}

export default App;

import React, { useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAuth0 } from '../react-auth0-spa';
import { useIdleTimer } from 'react-idle-timer'


const TimeoutAlert = () => {
    const { user, logout } = useAuth0();
    const [showTimeout, setShowTimeout] = useState<boolean>(false);

    const userStay = () => {
        setShowTimeout(false);
        reset();
        window.location.reload();
    }

    const handleClose = () => {
        setShowTimeout(false);
    }

    const handleOnIdle = () => {
        setShowTimeout(true);
    }


    const { reset } = useIdleTimer({
        timeout: 1000 * 60 * 30,// 30mins
        onIdle: handleOnIdle,
        debounce: 500,
        stopOnIdle: true
    });

    return (
        <Dialog
            open={showTimeout}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="alert-dialog-title">Hi {user ? user.given_name : ''},  this page has been timed out due to inactivity.</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Stay to refresh the page or choose to log out.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className='no-outline-btn' onClick={logout} color="primary">
                    Log out
          </Button>
                <Button className='no-outline-btn' onClick={userStay} color="primary" autoFocus>
                    Stay
          </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TimeoutAlert;
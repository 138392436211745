import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import config from '../config.json';
import { sha256 } from 'js-sha256';

const instrumentationKey = config.insights.instrumentationKey;
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: instrumentationKey
    }
});

ai.loadAppInsights();
const appInsights = ai.appInsights;

const trackEvent = (event: any, customProperties?: any) => {
    if (customProperties) {
        appInsights.trackEvent(event, customProperties);
    } else {
        appInsights.trackEvent(event);
    }
}

const trackException = (error: any) => {
    appInsights.trackException(error);
}

const trackTrace = (message: string) => {
    appInsights.trackTrace({ message: message })
}

const setInsightAuth = (userId: string) => {
    const securedUserId = sha256(userId);
    ai.setAuthenticatedUserContext(securedUserId);
}

const clearInsightAuth = () => {
    ai.clearAuthenticatedUserContext();
}

const trackPageView = (name: string, uri: string) => {
    appInsights.trackPageView({ name: name, uri: uri });
}

export { trackEvent, trackException, trackTrace, setInsightAuth, clearInsightAuth, trackPageView };



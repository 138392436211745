import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from '../appInsights';

function usePageViews() {
    let location = useLocation();
    useEffect(() => {
        trackPageView(location.pathname, location.pathname);
    }, [location]);
}

export default usePageViews;
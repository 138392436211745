import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import config from './config.json';
import history from './utilities/history';
import { Auth0Provider } from './utilities/react-auth0-spa';
import swConfig from './swConfig'
import { Router } from 'react-router-dom';

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.auth.domain}
    client_id={config.auth.clientId}
    redirect_uri={config.auth.redirectUrl}
    onRedirectCallback={onRedirectCallback}
    cacheLocation='memory'
    useRefreshTokens={true}
  >
    <Router history={history}>
      <App />
    </Router>
  </Auth0Provider>,
  document.getElementById('root')
);

serviceWorker.register(swConfig);
